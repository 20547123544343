import React from "react";
import classes from "./Button.module.css";

export default function Button({ primary, title, onClick, children }) {
  let btnClass = classes.btn + " ";

  if (primary) btnClass += classes.primary;
  else btnClass += classes.default;

  btnClass += " b-outline-on-focus";

  return (
    <button onClick={onClick} title={title} className={btnClass}>
      {children}
    </button>
  );
}
