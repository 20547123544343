import { ADD_RECENT_VIEWED_ITEMS } from "../types.js";

const initialState = {
  recentlyViewedItemsList: []
};

const recentlyViewedItemsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case ADD_RECENT_VIEWED_ITEMS:
      return {
        ...state,
        recentlyViewedItemsList: payload
      };
    default:
      return state;
  }
};

export default recentlyViewedItemsReducer;
