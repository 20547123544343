import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useLayoutEffect
} from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link, navigate } from "gatsby";

import { PREVIEW, PROJECT_LINK, VID } from "../project-config.js";

import NavMenu from "./NavMenu.jsx";
import Logo from "../assets/img/Logo.png";
import Drawer from "@material-ui/core/Drawer";
import MiniCart from "../components/AC-MiniCart/MiniCart.jsx";

import { cookieWindowShow } from "../../src/redux/actions/loginActions";
import CookieDialog from "../components/AC-Cookie/CookieDialog";
import Profile from "./Profile.jsx";
import { setHTMLElementFixedPosition } from "../functions/Utilities.js";
import SearchBarMobile from "../components/AC-Search/components/SearchBarMobile";
import WishList from "../components/AC-WishList/components/WishListCustomModal.jsx";
import { I18nContext } from "../i18n/index";
import capitalize from "../functions/capitalize";

import Location from "../assets/img/location.png";

import Async from "react-code-splitting";

import Bag from "../assets/img/bag.png";
import More from "../assets/img/More.png";
import { fetchingMenuSuccess } from "../redux/actions/menuActions.js";
import { useLocation } from "@reach/router";
import {
  handleMobileOrDesktop,
  setDocumentMode
} from "../redux/actions/mainActions.js";
import { FormattedNumber } from "react-intl";
import { updateBrandsGeolocation } from "../redux/actions/geoLocationActions.js";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import HeaderShopImg from "../assets/img/nbs/Shop_White_E.svg";
import LogoWhite from "../assets/img/nbs/Logo_White.svg";
import MapPin from "../assets/img/nbs/map-pin-marker.svg";
import ProfileIcon from "../assets/img/nbs/profile-icon.svg";
import CartIcon from "../assets/img/nbs/cart.svg";
import WishIcon from "../assets/img/wishlistWhite.png";
import { rgbToHex } from "@material-ui/core";
import { REDIRECT_KEYS, REDIRECT_SCP } from "../redux/links.js";
import Popup from "../components/AC-Notifications/Popup/Popup.jsx";
const SearchBar = () => <Async load={import("./SearchHelper")} />;

export default function MenuTopBar({ data, brands }) {
  const dataImg = useStaticQuery(graphql`
    query {
      Logo: file(relativePath: { eq: "logoFoot.png" }) {
        childImageSharp {
          fixed(width: 131) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const dispatch = useDispatch();
  const location = useLocation();
  const { translate, currency, priceConvert } = useContext(I18nContext);

  useEffect(() => {
    const navCats = {
      name: "Shop",
      vid: "20180521148",
      cid: "72181",
      thumbnail: "No Image",
      image: "No Image",
      position: "1",
      description: "Shop",
      metadescription:
        "This is a Demo Item. This product demonstrates item options. As options on the right side of the Micro Vault Midi, as an entry-level portable storage solution, is ideally suited to plug into a laptop",
      metakeywords: "Lenovo IdeaPad S110 Golf Shirts Sony Micro Vault Midi 4GB",
      URL: "shop",
      childs: [...data]
    };
    dispatch(fetchingMenuSuccess(navCats));

    //BRANDS GEOLOCATION
    dispatch(updateBrandsGeolocation(brands));
  }, []);
  const langCode = useSelector(state => state.mainReducer.lang, shallowEqual);

  const [loginModalOpened, setLoginModalOpened] = useState(false);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const suppliersBasketState = useSelector(
    state => state.sessionReducer.suppliersBasket,
    shallowEqual
  );

  const suppliersBasketCountState =
    suppliersBasketState &&
    Object.keys(suppliersBasketState).length > 0 &&
    Object.keys(suppliersBasketState).reduce((a, key) => {
      a += suppliersBasketState[key].products.length;
      return a;
    }, 0);

  /*  const basketState = useSelector(
    state => state.sessionReducer.basket,
    shallowEqual
  );

  const basketCountState = basketState && basketState.itemsCount;
 */
  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );
  const languageState = useSelector(
    state => state.mainReducer.lang,
    shallowEqual
  );
  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  const documentModeState = useSelector(
    state => state.mainReducer.documentMode,
    shallowEqual
  );
  //#region screen width listener starts
  let initialMobile;
  if (typeof window !== "undefined") {
    initialMobile = window.innerWidth;
  }

  const [currentScreenWidth, setCurrentScreenWidth] = useState(initialMobile);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleResize = () => {
        setCurrentScreenWidth(window.innerWidth);
      };
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  let isMobile;
  const mobileSize = 768;
  useEffect(() => {
    if (currentScreenWidth > mobileSize) {
      isMobile = false;
    } else {
      isMobile = true;
    }
    dispatch(handleMobileOrDesktop({ isMobile, currentScreenWidth }));
  }, [currentScreenWidth]);

  //#endregion screen width listener starts

  let name;

  const toggleDrawerMini = (side, open) => event => {
    if (event.key === "Tab" || event.key === "Shift") {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div role="presentation" onClick={toggleDrawerMini(side, false)}>
      <MiniCart close={() => toggleDrawerMini(side, false)} />
    </div>
  );

  // Lee
  // ? redux ?? cookieWindowState(null)
  // ???? cookie dialog
  let cookieWindowState = useSelector(
    state => state.loginReducer.cookieWindow,
    shallowEqual
  );

  const [navMenuOpen, setNavMenuOpen] = useState(false);

  const toggleDrawer = () => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setNavMenuOpen(!navMenuOpen);
  };

  // const toggleDrawersearch = () => event => {
  //   if (
  //     event &&
  //     event.type === "keydown" &&
  //     (event.key === "Tab" || event.key === "Shift")
  //   ) {
  //     return;
  //   }

  //   setNavMenuOpen(!navMenuOpen);
  // };

  useEffect(() => {
    setHTMLElementFixedPosition(navMenuOpen);
    return () => {
      setHTMLElementFixedPosition(false);
    };
  }, [navMenuOpen]);

  const NAV_LINKS = [
    {
      "Print a file": REDIRECT_SCP(REDIRECT_KEYS.printOnline)
    },

    { Products: REDIRECT_SCP(REDIRECT_KEYS.printOnline) },

    { Services: `${REDIRECT_SCP(REDIRECT_KEYS.services)}` },
    {
      "Safety Solutions":
        "https://www.staplescopyandprint.ca/PrintOnlineInfo/COVID19ResourceCentre.aspx"
    },

    {
      Deals:
        "https://www.staplescopyandprint.ca/PrintOnlineInfo/DealsCentreInfo.aspx"
    }
  ];

  const handleCategoryChange = (cid, cat, parents, longdesc) => {
    //  let catName = cat;
    if (isMobileState) {
      setNavMenuOpen(!navMenuOpen);
    }
    /*    console.info("handleCatChange", cid, cat, parents);
    if  (cat.includes("&amp;")) {
       catName = cat.replace("&amp;", "&");
     }

    const category = navCatsState.childs.filter(c =>  {
      let name = c.name;
      if (name.includes("&amp;")) {
        name = name.replace("&amp;", "&");
      }

      return name.toLowerCase() === catName.toLowerCase();
    });
    //dispatch(changeCategoryName(catName));

    if (cid != "72167" && cid != "72165") {
      // 432381 - stores // cat//Stores
      // dispatch(fetchCategoryFromRender(cid, catName, parents, "", category[0]));
    } */
  };

  const handleOpenModalToChangeDistance = () => {
    const button = document.getElementById("locationChangeBtn");
    button.click();
  };

  useEffect(() => {
    // (in Redux) cookieWindow = cookieAcceptance(null)    (in local)
    if (typeof localStorage !== undefined) {
      let decision = localStorage.getItem("cookieAcceptance");
      if (decision) {
        dispatch(cookieWindowShow(decision.isAccept));
      }
    }
  });

  //  ??handler???
  //  (in Redux) cookieWindow = true
  const changeCookieInRedux = () => {
    dispatch(cookieWindowShow(true));
  };

  // ??handler???
  // (in local)   cookieAcceptance = {"date": , "consent": , "isAccept": }
  const acceptCookieHandler = () => {
    let consent = {
      date: new Date(),
      consent: "Cookies accepted.",
      isAccept: true
    };
    if (typeof localStorage !== undefined) {
      localStorage.setItem("cookieAcceptance", JSON.stringify(consent));
    }
  };
  //
  const acceptCookieAndCloseWindow = () => {
    changeCookieInRedux();
    acceptCookieHandler();
  };

  const renderMyCartTextOrTheSumOfTheCart = () => {
    if (suppliersBasketCountState > 0) {
      let sumOfAllProducts = Object.keys(suppliersBasketState).reduce(
        (a, key) => {
          a += suppliersBasketState[key].products.reduce((acc, product) => {
            acc += product.price * product.qty;
            return acc;
          }, 0);
          return a;
        },
        0
      );

      return (
        <FormattedNumber
          value={sumOfAllProducts / priceConvert}
          style="currency"
          currency={currency}
        >
          {value => <b style={{ fontSize: "1.125em" }}>{value}</b>}
        </FormattedNumber>
      );
    }
    return translate("js.header.cart");
  };
  const renderIEPopup = () => {
    if (
      documentModeState &&
      localStorage.getItem("ieNotificationRead") !== "true"
    ) {
      let buttons = [];

      const onCloseIconClicked = () => {
        dispatch(setDocumentMode(null));
        localStorage.setItem("ieNotificationRead", "true");
      };

      buttons.push({
        primary: true,
        title: "OK",
        onClick: () => {
          onCloseIconClicked();
        }
      });

      if (documentModeState > 10 === false) {
        buttons.push({
          title: "Download Chrome",
          onClick: () => {
            window.open("https://www.google.ca/chrome");
          }
        });
      }

      return (
        <Popup
          title="You are using Internet Explorer as your browser"
          description={
            documentModeState > 10
              ? "For a better shopper experience we recommend you use an up to date browser such as Chrome, Firefox, Safari or  Microsoft Edge."
              : "Unfortunately your browser is not supported on this site. We recommend use an up to date browser such as Chrome, Firefox, Safari or  Microsoft Edge."
          }
          buttons={buttons}
          closeIconClicked={onCloseIconClicked}
        />
      );
    }
  };
  return (
    <>
      {renderIEPopup()}
      <div style={{ backgroundColor: "#747679" }}>
        <nav
          className="nav-extended"
          // style={{  padding: "4px 0px" }}
        >
          <div
            className="nav-wrapper"
            style={{
              backgroundColor: "#747676",
              paddingLeft: "12px",
              paddingRight: "12px"
            }}
          >
            <div
              className="header-container"
              style={{ maxWidth: "930px", width: "100%", margin: "0px" }}
            >
              {isMobileState ? (
                <React.Fragment>
                  <div className="icon-container">
                    <div
                      className="icon-wrapper"
                      style={{ marginRight: "16px" }}
                    >
                      <i
                        id="mobile-nav-icon"
                        open={navMenuOpen}
                        className="material-icons mobile-nav-icon"
                        onClick={toggleDrawer()}
                      >
                        <span></span>
                      </i>
                    </div>
                  </div>
                  <div
                    className="mobile-nav-bar-outer-container"
                    style={{ height: "4px" }}
                  >
                    <div
                      className="mobile-nav-bar-container"
                      open={navMenuOpen}
                    >
                      <div
                        id="nav-menu-drawer"
                        className={
                          langCode === "ar"
                            ? "rtl nav-wrapper actual-nav scroll-bar-thin-style"
                            : "nav-wrapper actual-nav scroll-bar-thin-style"
                        }
                      >
                        <div className="nav-content-wrapper">
                          <ul className="staticMenu">
                            {NAV_LINKS.map(link => {
                              const name = Object.keys(link)[0];
                              const url = Object.values(link)[0];
                              return (
                                <li key={link.url}>
                                  <Link
                                    to={url}
                                    dangerouslySetInnerHTML={{ __html: name }}
                                  ></Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}

              {/*<div className="logo-container">
              <Link to={"/"} className="brand-logo logo-i">
                <div className="menu-logo-wrapper">
                  <Img
                    className="img-responsive"
                    fixed={dataImg.Logo.childImageSharp.fixed}
                    alt="Open For Business Marketplace Logo"
                  />
                </div>
              </Link>
            </div> */}

              {/* <div className="icon-container">*/}

              {/* Français*/}
              {/* <WishList Style={{color:"white"}} />*/}

              {!isMobileState ? (
                <div
                  className="top-bar-text-wrapper"
                  style={{ paddingLeft: "10px" }}
                >
                  <a href="https://www.staplescopyandprint.ca/PrintOnlineInfo/ChequesInfo.aspx?lng=fr-CA">
                    Français
                  </a>
                </div>
              ) : (
                <div></div>
              )}

              <div
                onClick={() => {
                  navMenuOpen && setNavMenuOpen(false);
                }}
                className="nav-icons-container"
              >
                <div className="nav-icons-container">
                  <a href={REDIRECT_SCP(REDIRECT_KEYS.locations)}>
                    <div
                      className="icon-container seller-icon-container"
                      onClick={handleOpenModalToChangeDistance}
                    >
                      <div className="icon-wrapper">
                        {/* <span className="material-icons">location_on</span> */}
                        <div
                          style={{
                            height: "36px",
                            width: "36px",
                            marginTop: "5px"
                          }}
                        >
                          <img
                            alt="Find a Store"
                            aria-hidden="true"
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "block",
                              marginBottom: "2px",
                              marginTop: "auto"
                            }}
                            src={MapPin}
                          ></img>
                        </div>
                        <div className="top-bar-text-wrapper">Find a Store</div>
                      </div>
                    </div>
                  </a>

                  <div className="logo-mini-splitter"></div>

                  <a
                    href={`${REDIRECT_SCP(REDIRECT_KEYS.account)}`}
                    className="icon-container login-icon-container"
                    //onClick={event => handleLoginIconClicked(event)}
                  >
                    {/* <Profile
                    modalOpenedByIcon={loginModalOpened}
                    setModalOpened={setLoginModalOpened}
                  /> */}
                    <img
                      style={{ marginRight: "8px" }}
                      src={ProfileIcon}
                      aria-hidden="true"
                      alt="My Account"
                    />
                    <div className="top-bar-text-wrapper">My Account</div>
                  </a>

                  <div className="logo-mini-splitter"></div>

                  <a
                    id="cart-icon-btn"
                    class="icon-container"
                    href={`${REDIRECT_SCP(REDIRECT_KEYS.cart)}`}
                  >
                    <div className="icon-text">
                      <span className="icon-action-text"></span>
                    </div>
                    <img
                      style={{ marginRight: "8px" }}
                      aria-hidden="true"
                      src={CartIcon}
                      alt="Cart Icon"
                    />
                    {/* Placeholder image source for now */}
                    <div className="top-bar-text-wrapper">Cart</div>
                    {/* </div> */}
                  </a>

                  <Drawer
                    anchor={langCode === "ar" ? "left" : "right"}
                    open={langCode === "ar" ? state.left : state.right}
                    onClose={
                      langCode === "ar"
                        ? toggleDrawerMini("left", false)
                        : toggleDrawerMini("right", false)
                    }
                  >
                    {langCode === "ar" ? sideList("left") : sideList("right")}
                  </Drawer>

                  {/* <Link
                  className="icon-container seller-icon-container"
                  to={"/seller-registration"}
                >
                  <div className="icon-wrapper">
                    <span className="material-icons">storefront</span>
                  </div>
                  <div className="login-text-container">
                    <span className="icon-action-text">
                      Seller
                      <br />
                      Sign Up
                    </span>
                  </div>
                </Link> */}
                </div>
              </div>
            </div>
          </div>

          <div
            className={!isMobileState ? "nav-extended hidden" : "nav-wrapper"}
          >
            <div
              className="h-container-ml"
              style={{ backgroundColor: "rgb(66,66,66", marginTop: "5px" }}
            >
              <div
                className="h-container-ml"
                style={{ padding: "2%", paddingRight: "0%" }}
              >
                <div className="h-container-ml">
                  <a
                    href="https://www.staples.ca/"
                    style={{ marginRight: "2px", marginLeft: "auto" }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "auto",
                        marginTop: "11px",
                        maxHeight: "37px",
                        maxWidth: "170px"
                      }}
                      src={LogoWhite}
                      alt="Staples Canada"
                    />
                  </a>
                </div>

                <div
                  style={{ width: "5%" }}
                  style={{
                    alignItems: "center",
                    display: "flex",
                    padding: "2px",
                    paddingTop: "0px"
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      width: "1px",
                      height: "55%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "flex"
                    }}
                  >
                    {" "}
                  </div>
                </div>

                <div className="h-container-ml">
                  <a
                    href="https://www.staplescopyandprint.ca/default.aspx"
                    style={{ marginLeft: "2%" }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "auto",
                        marginTop: "9px",
                        maxHeight: "40.7969px",
                        maxWidth: "200px"
                      }}
                      src={HeaderShopImg}
                      alt="Print & Marketing Services"
                    />{" "}
                  </a>
                </div>
              </div>

              <div
                className="h-container-ml"
                style={{
                  height: "123px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  maxWidth: "275px",
                  paddingRight: "2%",
                  paddingLeft: "2%"
                }}
              ></div>
            </div>
          </div>
        </nav>
      </div>

      <nav
        className={isMobileState ? "nav-extended hidden" : "nav-extended"}
        style={{
          boxShadow: "none",
          background: "#c2ff61",
          padding: "0px 0px",
          //borderTop: "2px solid #000",
          maxWidth: "100%"
        }}
      >
        <div
          className="nav-wrapper"
          style={{ backgroundColor: "#444444", MaxWidth: "930px" }}
        >
          <div
            className="h-container-ml"
            style={{ maxWidth: "930px", margin: "auto" }}
          >
            <div className="h-container-ml" style={{ marginLeft: "24px" }}>
              <div className="stps-cont">
                <a href="https://www.staples.ca/">
                  <img
                    style={{
                      width: "170px",
                      height: "37px",
                      marginTop: "11px"
                    }}
                    src={LogoWhite}
                    alt="Staples Canada"
                  />
                </a>
              </div>
              <div className="logo-line-splitter"></div>

              <div className="stps-cont2">
                <a href="https://www.staplescopyandprint.ca/default.aspx">
                  <img
                    style={{
                      width: "200px",
                      height: "40.7969px",
                      marginTop: "9px"
                    }}
                    src={HeaderShopImg}
                    alt="Print & Marketing Services"
                  />{" "}
                </a>
              </div>
            </div>

            <div
              className="h-container-ml"
              style={{ height: "123px", marginRight: "12px" }}
            >
              <div style={{ width: "100%", maxWidth: "50px" }}></div>

              <SearchBar />
            </div>
          </div>
        </div>
        {/* The above div is to contain the mid logo + search*/}

        <div className="nav-wrapper">
          <div className="low-bar-wrapper">
            <div className="h-container-ml" style={{ margin: "auto" }}>
              <a href={REDIRECT_SCP(REDIRECT_KEYS.printOnline)}>Print a file</a>
              <a href={REDIRECT_SCP(REDIRECT_KEYS.printOnline)}>Products</a>
              <a href={`${REDIRECT_SCP(REDIRECT_KEYS.services)}`}>Services</a>
              <a href="https://www.staplescopyandprint.ca/PrintOnlineInfo/COVID19ResourceCentre.aspx">
                Safety Solutions
              </a>
              <a href="https://www.staplescopyandprint.ca/PrintOnlineInfo/DealsCentreInfo.aspx">
                Deals
              </a>
            </div>
          </div>{" "}
          {/* <SearchBar /> */}
        </div>
        {/* The above div is to contain the site nav/category links */}
      </nav>
    </>
  );
}
