import React, { useRef } from "react";
import Button from "../../AC-ProductPage/Components/ChequeCustomization/components/Button";
import classes from "./Popup.module.css";

export default function Popup({
  title,
  description,
  buttons,
  closeIconClicked
}) {
  const firstElRef = useRef(null);

  return (
    <div
      onTransitionEnd={() => firstElRef.current && firstElRef.current.focus()}
      role="dialog"
      tabIndex="0"
      className={classes.container}
    >
      <div className={classes.wrapper}>
        <div className={classes.titleWrapper}>
          <h1>{title}</h1>
          <i
            ref={firstElRef}
            tabIndex="0"
            role="button"
            onClick={closeIconClicked}
            onKeyPress={e => {
              const keyID = e.keyCode;

              if (keyID == 32 || keyID == 13) {
                e.target.click();
              }
            }}
            className="material-icons b-outline-on-focus"
          >
            close
          </i>
        </div>
        <div
          className={classes.description}
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
        {buttons && buttons.length > 0 && (
          <div className={classes.buttonsWrapper}>
            {buttons.map(button => {
              return (
                <Button
                  primary={button.primary}
                  onClick={button.onClick}
                  title={button.title}
                >
                  {button.title}
                </Button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
