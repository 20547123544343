import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";

import FooterContentFunc from "./footerContent";
import AnchorElement from "../../functions/AnchorElement.jsx";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import FacebookIcon from "../../assets/img/nbs/facebook.svg";
import TwitterIcon from "../../assets/img/nbs/twitter.svg";
import LinkedinIcon from "../../assets/img/nbs/linkedin.svg";
import YoutubeIcon from "../../assets/img/nbs/youtube.svg";

import HeaderShopImg from "../../assets/img/nbs/Shop_White_E.svg";
import LogoWhite from "../../assets/img/nbs/Logo_White.svg";

import { I18nContext } from "../../i18n/index";
import { REDIRECT_KEYS, REDIRECT_SCP } from "../../redux/links";
function Footer() {
  const data = useStaticQuery(graphql`
    query {
      Logo: file(relativePath: { eq: "logoFoot.png" }) {
        childImageSharp {
          fixed(width: 230) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const { translate } = React.useContext(I18nContext);

  const langState = useSelector(state => state.mainReducer.lang, shallowEqual);

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  const [footerContent, setFooterContent] = useState({});

  useEffect(() => {
    setFooterContent(FooterContentFunc(langState, loginNameState));
    return () => {
      setFooterContent({});
    };
  }, [langState]);

  const [activeFooterSection, setActiveFooterSection] = useState("");

  const renderLinks = arrContent => {
    return arrContent.map((content, i) => (
      <li {...content.parent.attributes} key={i}>
        {content.children.map((childContent, i) => {
          return <AnchorElement {...childContent} key={i} />;
        })}
      </li>
    ));
  };

  const finePrint = (
    <small>Copyright © 2020 Staples Canada ULC. All rights reserved.</small>
  );
  if (footerContent && Object.keys(footerContent).length > 0) {
    if (isMobileState) {
      const handleOnClickFooterSection = e => {
        const { target: clickedFooterSectionTarget } = e.target.dataset;
        setActiveFooterSection(
          activeFooterSection !== clickedFooterSectionTarget &&
            clickedFooterSectionTarget
        );

        console.info(
          "activeFooterSection",
          activeFooterSection,
          clickedFooterSectionTarget
        );
      };

      const handleWhatIconToDisplay = footerSectionName => {
        return activeFooterSection === footerSectionName
          ? `remove_circle`
          : `add_circle`;
      };
      return (
        <footer style={{ backgroundColor: "rgb(116,118,121)", color: "white" }}>
          {/* <div style={{backgroundColor: "rgb(66, 66, 66)", width:"100%", paddingBottom:"6px",paddingTop:"3px"}}>
                               <div className="h-container-ml" style={{maxWidth:"50%", marginLeft:"auto", marginRight:"auto"}}>        
                                <div className="h-container-ml">
                                    <a href="https://www.staples.ca/">
                                    <img style={{width:"100%", height:"auto", marginTop:"11px"}} src={LogoWhite} alt="Staples Canada" />  
                                    </a>
                                </div>
                              <div className="logo-line-splitter"> </div>   
                                  <div className="h-container-ml">
                                      <a href="https://www.staplescopyandprint.ca/default.aspx">
                                    <img style={{width:"100%", height:"auto", marginTop: "9px"}} src={HeaderShopImg} alt="Print & Marketing Services"/> </a>
                                  </div>           
                                </div>
                             </div> */}

          <div className="new-footer" style={{ maxWidth: "100%" }}>
            <div className="columns " style={{ maxWidth: "100%" }}>
              {/* <div className="footer_Line"> */}
              <div>
                {/* <div className="footerLogo pull-left">
                    <Img
                      fixed={data.Logo.childImageSharp.fixed}
                      alt="Avetti eCommerce Logo"
                    />
                  </div> */}
              </div>
              {/* </div> */}
              {/* Mobile Version */}
              <div
                className="v-container"
                style={{
                  maxWidth: "930px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex"
                }}
              >
                {/* <div className="liHead" id="about-menu">
                        {translate("vm.footer.learnmore")}
                        <i
                          className="hidden-md hidden-lg hidden-sm fas fa-plus-circle global--one-column pull-right"
                          id="global--mobile-about-expand"
                        ></i>
                      </div> */}
                <div className="v-container">
                  <div class="h-container-ml" style={{ marginTop: "12px" }}>
                    <div
                      class="h-cont-footer-box"
                      style={{ minWidth: "33.333%" }}
                    >
                      <div
                        class="v-container"
                        style={{
                          minHeight: "90px",
                          minWidth: "33.333%",
                          maxWidth: "33.3333%"
                        }}
                      >
                        <span>Corporate Information</span>
                        <ul>
                          <li>
                            <a href={`${REDIRECT_SCP(REDIRECT_KEYS.about)}`}>
                              About Us
                            </a>
                          </li>
                          <li>
                            <a href={`${REDIRECT_SCP(REDIRECT_KEYS.careers)}`}>
                              Careers
                            </a>
                          </li>
                          <li>
                            <a href="http://www.essentialaccessibility.com/staples/copyprint/en?utm_source=staplescopyandprintenhomepage&utm_medium=iconlarge&utm_term=eachannelpage&utm_content=header&utm_campaign=staplescopyandprinten">
                              Accessbility
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="h-cont-footer-box" style={{}}>
                      <div class="v-container" style={{ minHeight: "90px" }}>
                        <span>Customer Service</span>
                        <ul>
                          <li>
                            <a
                              href={`${REDIRECT_SCP(REDIRECT_KEYS.priceList)}`}
                            >
                              Pricing
                            </a>
                          </li>
                          <li>
                            <a href={`${REDIRECT_SCP(REDIRECT_KEYS.faq)}`}>
                              F.A.Q.'s
                            </a>
                          </li>
                          <li>
                            <a
                              href={`${REDIRECT_SCP(REDIRECT_KEYS.priceList)}`}
                            >
                              Policies & Legal
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="h-cont-footer-box" style={{}}>
                      <div class="v-container" style={{ minHeight: "90px" }}>
                        <span>Help</span>
                        <ul>
                          <li>
                            <a href={`${REDIRECT_SCP(REDIRECT_KEYS.account)}`}>
                              My Account
                            </a>
                          </li>
                          <li>
                            <a
                              href={`${REDIRECT_SCP(REDIRECT_KEYS.locations)}`}
                            >
                              Store Locator
                            </a>
                          </li>
                          <li>
                            <a
                              href={`${REDIRECT_SCP(REDIRECT_KEYS.contactUs)}`}
                            >
                              Contact Us
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div
                    class="h-container-ml"
                    style={{ marginBottom: "12px", marginTop: "24px" }}
                  >
                    <div
                      class="h-cont-footer-box"
                      style={{ maxWidth: "33.3333%" }}
                    >
                      <div class="v-container" style={{}}>
                        <span>Air Miles Program</span>
                        <p>
                          <a>
                            Earn Air Miles
                            <span
                              style={{ fontSize: "50%", verticalAlign: "top" }}
                            >
                              ®†
                            </span>
                            on your purchases.
                          </a>
                          <a href={`${REDIRECT_SCP(REDIRECT_KEYS.airMiles)}`}>
                            Learn more about the program &gt;
                          </a>
                        </p>
                      </div>
                    </div>

                    <div
                      class="h-cont-footer-box"
                      style={{ minHeight: "90px" }}
                    >
                      <div class="v-container" style={{ minHeight: "90px" }}>
                        <span>Join Staples on:</span>
                        <ul>
                          <li>
                            {/* <li>img line</li>  */}
                            <div style={{ height: "36.72px" }}>
                              <div
                                class="footer-mini-images-container"
                                style={{ height: "27px" }}
                              >
                                <a href="http://www.facebook.com/StaplesCanada">
                                  <img src={FacebookIcon} alt="Facebook" />
                                </a>
                                <a href="https://twitter.com/#!/staplescanada">
                                  <img src={TwitterIcon} alt="Twitter" />
                                </a>
                                <a href="http://www.linkedin.com/company/staples-canada">
                                  <img src={LinkedinIcon} alt="LinkedIn" />
                                </a>
                                <a href="http://www.youtube.com/staplestechtv">
                                  <img src={YoutubeIcon} alt="YouTube" />
                                </a>
                              </div>
                            </div>
                            <a href="https://www.staples.ca/sbdca/en_CA/cre/programs/email/index.html?icid=HP:HP:STAPLESPRINTFOOTER:EmailSignup:RESP::1">
                              <p>Sign up for Staples offers &gt;</p>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="footer-container" >
                <div
                  className="liHead first"
                  id="about-menu"
                  onClick={e => handleOnClickFooterSection(e)}
                  data-target="about"
                  aria-controls="about"
                  aria-expanded={activeFooterSection === "about"}
                >
                  {translate("vm.footer.learnmore")}
                  <i className="material-icons footer-icon" data-target="about">
                    {handleWhatIconToDisplay("about")}
                  </i>
                </div>
                <div
                  className="menu-footer"
                  aria-labelledby="about-menu"
                  role="region"
                  id="about"
                  style={{
                    display: activeFooterSection === "about" ? "block" : "none"
                  }}
                >
                  <ul>{renderLinks(footerContent.about)}</ul>
                </div>

                <div
                  className="liHead"
                  id="shop-menu"
                  onClick={e => handleOnClickFooterSection(e)}
                  data-target="shop"
                  aria-controls="shop"
                  aria-expanded={activeFooterSection === "shop"}
                >
                  {translate("vm.footer_contact")}
                  <i className="material-icons footer-icon" data-target="shop">
                    {handleWhatIconToDisplay("shop")}
                  </i>
                </div>
                <div
                  className="menu-footer"
                  id="shop"
                  aria-labelledby="shop-menu"
                  role="region"
                  style={{
                    display: activeFooterSection === "shop" ? "block" : "none"
                  }}
                >
                  <ul>{renderLinks(footerContent.shop)}</ul>
                </div>

                <div
                  className="menu-footer"
                  id="socialMedia"
                  aria-labelledby="socialMedia-menu"
                  role="region"
                  style={{
                    display: "block"
                  }}
                >
                  <ul>{renderLinks(footerContent.socialMedia)}</ul>
                </div>
              </div> */}
              <div id="finePrint">{finePrint}</div>
            </div>
          </div>
        </footer>
      );
    } else {
      return (
        <div>
          <footer id="footer" style={{ maxHeight: "296px", height: "296px" }}>
            <div className="new-footer" style={{ maxWidth: "100%" }}>
              <div
                className="footer-container"
                style={{
                  maxWidth: "930px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex"
                }}
              >
                {/* <div className="liHead" id="about-menu">
                        {translate("vm.footer.learnmore")}
                        <i
                          className="hidden-md hidden-lg hidden-sm fas fa-plus-circle global--one-column pull-right"
                          id="global--mobile-about-expand"
                        ></i>
                      </div> */}

                <div class="h-cont-footer-box">
                  <div class="v-container" style={{ minHeight: "90px" }}>
                    <span>Corporate Information</span>
                    <ul>
                      <li>
                        <a href={`${REDIRECT_SCP(REDIRECT_KEYS.about)}`}>
                          About Us
                        </a>
                      </li>
                      <li>
                        <a href={`${REDIRECT_SCP(REDIRECT_KEYS.careers)}`}>
                          Careers
                        </a>
                      </li>
                      <li>
                        <a href="http://www.essentialaccessibility.com/staples/copyprint/en?utm_source=staplescopyandprintenhomepage&utm_medium=iconlarge&utm_term=eachannelpage&utm_content=header&utm_campaign=staplescopyandprinten">
                          Accessbility
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="h-cont-footer-box">
                  <div
                    class="v-container"
                    style={{ minHeight: "90px", minWidth: "87px" }}
                  >
                    <span>Help</span>
                    <ul>
                      <li>
                        <a href={`${REDIRECT_SCP(REDIRECT_KEYS.account)}`}>
                          My Account
                        </a>
                      </li>
                      <li>
                        <a href={`${REDIRECT_SCP(REDIRECT_KEYS.locations)}`}>
                          Store Locator
                        </a>
                      </li>
                      <li>
                        <a href={`${REDIRECT_SCP(REDIRECT_KEYS.contactUs)}`}>
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="h-cont-footer-box">
                  <div class="v-container" style={{ minHeight: "90px" }}>
                    <span>Customer Service</span>
                    <ul>
                      <li>
                        <a href={`${REDIRECT_SCP(REDIRECT_KEYS.priceList)}`}>
                          Pricing
                        </a>
                      </li>
                      <li>
                        <a href={`${REDIRECT_SCP(REDIRECT_KEYS.faq)}`}>
                          F.A.Q.'s
                        </a>
                      </li>
                      <li>
                        <a href={`${REDIRECT_SCP(REDIRECT_KEYS.policy)}`}>
                          Policies & Lega
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="h-cont-footer-box">
                  <div class="v-container" style={{ minHeight: "90px" }}>
                    <span>Air Miles Program</span>
                    <ul>
                      <li>
                        Earn Air Miles
                        <span style={{ fontSize: "50%", verticalAlign: "top" }}>
                          ®†
                        </span>
                        on your purchases.
                        <a href={`${REDIRECT_SCP(REDIRECT_KEYS.airMiles)}`}>
                          Learn more about the program &gt;
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="h-cont-footer-box">
                  <div
                    class="v-container"
                    style={{ minHeight: "90px", minWidth: "170px" }}
                  >
                    <span>Join Staples on:</span>
                    <ul>
                      <li>
                        {/* <li>img line</li>  */}
                        <div style={{ height: "36.72px" }}>
                          <div
                            class="footer-mini-images-container"
                            style={{ height: "27px" }}
                          >
                            <a href="http://www.facebook.com/StaplesCanada">
                              <img src={FacebookIcon} alt="Facebook" />
                            </a>
                            <a href="https://twitter.com/#!/staplescanada">
                              <img src={TwitterIcon} alt="Twitter" />
                            </a>
                            <a href="http://www.linkedin.com/company/staples-canada">
                              <img src={LinkedinIcon} alt="LinkedIn" />
                            </a>
                            <a href="http://www.youtube.com/staplestechtv">
                              <img src={YoutubeIcon} alt="YouTube" />
                            </a>
                          </div>
                        </div>
                        <a href="https://www.staples.ca/sbdca/en_CA/cre/programs/email/index.html?icid=HP:HP:STAPLESPRINTFOOTER:EmailSignup:RESP::1">
                          <p>Sign up for Staples offers &gt;</p>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </footer>
          <div
            style={{
              maxWidth: "930px",
              marginLeft: "auto",
              marginRight: "auto"
            }}
          >
            <div class="fineprint">{finePrint} </div>
          </div>
        </div>
      );
    }
  } else {
    return null;
  }
}

export default Footer;
