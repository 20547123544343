import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, withPrefix } from "gatsby";

import { Provider } from "react-redux";
import { I18nContextProvider } from "../i18n/index";
import { IntlProvider } from "react-intl";

import configureStore from "../redux/index.js";

import MailchimpSub from "../components/AC-Modals/MailchimpSub";
import MenuTopBar from "../header/MenuTopBar";

import Common from "../shared/components/Common";
import Footer from "../components/AC-Footer/Footer";

import { LINK_DISTRIBUTION } from "../project-config.js";

import "../assets/css/googleFonts.css";
import "../assets/css/menu.css";
import "../assets/css/minified.css";
import "../assets/css/avetti.css";
import "../assets/css/mailchimp.css";
import "../assets/font/NewFontsForCustomizer/fontstyles.css";

/* import "../assets/css/staples.min.css";
import "../assets/css/uiCommon.css"; */
import "../assets/css/sl.css";
import "../assets/css/b2b2c.css";
import "../assets/css/scpen.css";
import "../assets/css/fontello.css";
import "../assets/jss/demo-ar.scss";
import "../assets/css/purgeignore.css";

import PromotionComponent from "../components/AC-Advertising/PromotionComponent";

import "../assets/css/nbs.css";

export const store = configureStore();

function handleMessage(event) {
  const receivedMessage = event.data;
  const iframeHeader = document.getElementById("iframe-container");
  const iframeFooter = document.getElementById("iframe-footer");

  const bodyBlock = document.getElementById("body");

  if (
    receivedMessage === "STAPLES_IFRAME_STORE_LOCATOR_CLOSE" ||
    receivedMessage === "STAPLES_IFRAME_SEARCH_RESULT_CLOSE" ||
    receivedMessage === "STAPLES_IFRAME_MENU_CLOSE"
  ) {
    iframeHeader.style.width = "auto";
    iframeHeader.style.height = "auto";
    iframeHeader.style.position = "static";
    iframeHeader.style.top = "auto";
    iframeHeader.style.right = "auto";
    iframeHeader.style.bottom = "auto";
    iframeHeader.style.left = "auto";
    iframeHeader.style.zIndex = "1";
    document.body.style.overflow = "auto";
    bodyBlock.style.marginTop = "0px";
  }

  setTimeout(() => {
    if (
      receivedMessage === "STAPLES_IFRAME_SEARCH_RESULT_OPEN" ||
      receivedMessage === "STAPLES_IFRAME_STORE_LOCATOR_OPEN"
    ) {
      document.body.style.overflow = "hidden";
    }
    if (
      receivedMessage === "STAPLES_IFRAME_STORE_LOCATOR_OPEN" ||
      receivedMessage === "STAPLES_IFRAME_SEARCH_RESULT_OPEN" ||
      receivedMessage === "STAPLES_IFRAME_MENU_OPEN"
    ) {
      iframeHeader.style.width = "100%";
      iframeHeader.style.height = "100vh";
      iframeHeader.style.position = "absolute";
      iframeHeader.style.top = "0";
      iframeHeader.style.right = "0";
      iframeHeader.style.bottom = "0";
      iframeHeader.style.left = "0";
      iframeHeader.style.zIndex = "2";

      bodyBlock.style.marginTop = "202px";
    }
  }, 10);

  if (receivedMessage?.event === "STAPLES_IFRAME_FOOTER_SIZE_CHANGE") {
    if (iframeFooter) iframeFooter.style.height = `${receivedMessage.height}px`;
  }

  if (typeof window !== "undefined")
    if (receivedMessage.locale) {
      if (receivedMessage.locale == "fr-CA") {
        window.location.href = `${LINK_DISTRIBUTION}/services/lang/fr`;
      }
    }
}

if (typeof window !== "undefined")
  window.addEventListener("message", event => {
    console.log("message33", event);
    handleMessage(event);
  });

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      allAvettiMenu {
        nodes {
          childs {
            name
            vid
            cid
            thumbnail
            image
            position
            description
            metadescription
            metakeywords
            URL
            childs {
              name
              vid
              cid
              thumbnail
              image
              position
              description
              metadescription
              metakeywords
              URL
            }
          }
        }
      }
    }
  `);

  return (
    <I18nContextProvider>
      <IntlProvider locale={"en"}>
        <Provider store={store}>
          <Common />
          <div>
            <MenuTopBar data={data.allAvettiMenu.nodes[0].childs} brands={[]} />
            <PromotionComponent />
          </div>

          <div class="iframe-container" id="iframe-container">
            <iframe
              title="header"
              id="iframe-header"
              src="https://www.staplescopyandprint.ca/pages/iframe/header?lng=en-CA"
              width="100%"
              height="100%"
            ></iframe>
          </div>

          <div id="body">
            {children}
            <MailchimpSub />
          </div>
          {/* <Footer /> */}
          <div id="iframe-container-footer">
            <iframe
              title="footer"
              id="iframe-footer"
              allowFullScreen
              src="https://www.staplescopyandprint.ca/pages/iframe/footer?lng=en-CA"
              width="100%"
            ></iframe>
          </div>
        </Provider>
      </IntlProvider>
    </I18nContextProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
